import React from 'react';
// import logo from '../shared/images/logo.svg';
// import Home from './Home/Home';
import Header from './Header/Header';
// import Footer from './Footer/Footer';
// import Modal from '../shared/components/Modal/Modal'
// import Overlay from '../shared/components/Modal/Overlay'

function App() {
  return (
    <div className="App">
      {/* <h2 className="black">Coming soon...</h2> */}
      <Header />
      <h2 className="black">Coming soon...</h2>

      {/* <Home />
      <Footer /> */}
    </div>
  );
}

export default App;
