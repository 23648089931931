import React from 'react';
// import Modal from '../../shared/components/Modal/Modal';
//
import logo from '../../shared/images/logos/logo_transparent_background.png';
// import logo from '../../shared/images/logo_transparent_background.png';




function Header() {
  // const [modal, setModal] = useState(false);
  // const [modalAppear, setModalAppear] = useState();
  // const [overlay, setOverlay] = useState(false);

  // const toggleModal = () => {
  //   if(modal === false) {
  //     setModal(<Modal toggleModal={closeModal} />);
  //   } else {
  //     closeModal();
  //   }
  // }
  // const closeModal = () => {
  //   setModal(false);
  // }


  return (
    <header>
      <div className="grid-x header">
        <div className="cell small-12 medium-5">
          <div className="definition-container">
            <div className="definition-wrapper">
              <h2 className="black">spin <span className="part-of-speech">noun</span></h2>
              <div className="pronunciation">ˈspin</div>
              <p className="definition">a special point of view, emphasis, or interpretation presented for the purpose of influencing opinion</p>
            </div>
          </div>
        </div>

        <div className="cell small-12 medium-2 headline-container center">
          <div className="logo-container">
            {/* <div className="definition-wrapper"> */}
              <div className="logo-wrapper"><img src={logo} className="logo" alt="" /><h1 className="headline">Spin-Concordance</h1></div>
              {/* <h1 className="headline">Spin-Concordance</h1> */}
            </div>
          {/* </div> */}
        </div>

          <div className="cell small-12 medium-5">
            <div className="definition-container">
              <div className="definition-wrapper">
                <h2 className="black">Concordance <span className="part-of-speech">noun</span></h2>
                <div className="pronunciation">kən-ˈkȯr-dᵊn(t)s</div>
                <p className="definition">an alphabetical index of the principal words in a book or the works of an author with their immediate contexts</p>
              </div>
            </div>
          </div>
      </div>
    </header>
  )
};

export default Header
